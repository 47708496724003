.visualizationModalBackdrop {
  background-color: rgba(27, 27, 27, 0.2);
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  top: 0;
  left: 0;
  position: absolute;
}

/* This is the minimized view */
.visualizationModalContentsMinimized {
  /* This is for the background for content in the modal*/
  height: 100%;
  width: 64%;
  background-color: #ffffff;
  border: 0;
  margin: 0;
  position: fixed;
  top: 50%;
  right: 0%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(0%, -50%);
  border-radius: 8px;
}

.closeButtonStyling {
  background-color: #ffffff !important;
  float: left;
  margin-top: 29px;
  margin-left: 1.25%;
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  border: 0 !important;
}

.closeButtonIconStyling {
  height: 2%;
}

.expandButtonStyling {
  background-color: #ffffff !important;
  float: right;
  margin-top: 34px;
  margin-right: 35px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  border: 0 !important;
  position: absolute;
  right: 10px;
  top: 10px;
}

.expandIconSizing {
  height: 2%;
  width: auto;
}

.modalTitleDescriptor {
  margin-left: 2%;
  margin-bottom: 1%;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.3px;
  color: #2e2d29;
}

.modalTitleDescriptor2 {
  margin-top: 75px;
  margin-left: 4%;
  margin-bottom: 1%;
  font-family: "IBM Plex Sans" !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: -0.02em;
  color: #2e2d29;
}

.modalDescriptorContent {
  margin-left: 2%;
  margin-bottom: 3%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #2e2d29;
}

.modalContentDescription {
  margin-left: 4%;
  margin-top: 16px;
  margin-bottom: 20px;
  width: 561px;
  height: 66px;
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  color: #2e2d29;
}

.flexboxforshifting {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 32px 0px;
  width: 61.03896%;
  height: 43px;
  margin-left: 2%;
}

.shiftbar {
  height: 4.19921875%;
  width: 36.147186%;
  position: absolute;
  left: 2%;
  border: 1px solid #c0c0bf;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #2e2d29;
  padding-left: 2.05%;
  outline: none;
}

.shiftbar2 {
  height: 43px;
  width: 541px;
  position: absolute;
  left: 4%;
  border: 1px solid #c0c0bf;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #2e2d29;
  padding-left: 2.05%;
  outline: none;
}

.shiftbar::placeholder {
  color: black;
  font-weight: bold;
}

.shiftbar2::placeholder {
  color: #979694;
}

.shiftDataButton {
  position: absolute;
  width: 11.255411255%;
  height: 4.19921875%;
  left: 40%;
  background: #8c1515;
  border-radius: 4px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.shiftDataButton2 {
  position: absolute;
  width: 70px;
  height: 43px;
  left: 4%;
  top: 83%;
  background: #8c1515;
  border-radius: 4px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  padding-top: 0.58em;
  padding-bottom: 0.575em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.shiftDataButton:hover {
  color: #ffffff !important;
  background-color: #701010;
}

.shiftDataButton2:hover {
  color: #ffffff !important;
  background-color: #701010;
}

.undoButton {
  position: absolute;
  width: 78px;
  height: 24px;
  right: 40.2%;
  margin-top: 2px;
  margin-bottom: 2px;
  letter-spacing: 0.2px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  text-align: right;
  color: #2e2d29;
  border: 0;
  background-image: url("../img/undoIcon.png");
  background-repeat: no-repeat;
  background-size: 22px 18px;
  background-position: top 2px left 1px;
}

.undoButton:hover {
  color: #701010;
  border: 1px solid #701010;
}

.statsBox {
  float: left;
  position: absolute;
  width: auto;
  height: 5.25em;
  margin-left: 0.75em;
  margin-top: 0.75em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: center;
  align-content: stretch;
}

.averageContainer {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  width: auto;
  height: 68px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: center;
  align-content: normal;
}

.statsAverage {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 2.375em;
  line-height: 68px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: #2e2d29;
}

.averageUnit {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 1.5em;
  line-height: 30.17px;
  letter-spacing: 0.3px;
  color: #2e2d29;
  padding-top: 0.425em;
}

.lineStats {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  border-left: 1px solid #c0c0bf;
  width: 1px;
  margin-left: 24px;
  margin-right: 24px;
  height: 100%;
}

.descriptorsContainer {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  height: 44px;
  margin-top: 18px;
  width: auto;
}

.averageDescriptor {
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 600;
  line-height: 18.75px;
  letter-spacing: 0px;
  text-align: left;
  color: #2e2d29;
}

.descriptor2 {
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #2e2d29;
}

.timeselectionBox {
  position: absolute;
  width: 30em;
  height: 2.25em;
  right: 3.5em;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: flex-end;
  align-content: flex-end;
}

.timeselectionBox2 {
  position: absolute;
  width: 21em;
  height: 3em;
  right: 5%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  background: #ffffff;
}

.calendarModal {
  width: 17em;
  height: 2.5em;
  background: #ffffff;
  border: none;
  font-size: 18px;
  font-family: "Roboto";
  color: #2e2d29;
  border: 1px solid #c0c0bf;
  border-radius: 4px;
}

.visualizationSelectionBox {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.calendarselectionModal {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.floatvisualnavbuttons {
  z-index: 2;
}

.timelinkstyling {
  color: black;
  text-decoration: none;
}

.timelinkstylingactive {
  color: red;
  text-decoration: none;
}

/* Maximized view for visualizations */

.visualizationModalContentsMaximized {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  border: 0;
  margin: 0;
  position: fixed;
  top: 50%;
  right: 0%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(0%, -50%);
  border-radius: 8px;
}

.expandIconSizingMax {
  height: 18px;
  width: auto;
}

.modalTitleDescriptorMax {
  margin-left: 2%;
  margin-bottom: 11px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.3px;
  color: #2e2d29;
}

.modalDescriptorContentMax {
  margin-left: 2%;
  margin-bottom: 32px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #2e2d29;
}

.flexboxforshiftingMax {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-bottom: 30px;
  width: 619px;
  height: 43px;
  margin-left: 2%;
}

.shiftbarMax {
  height: 4.19921875%;
  width: 334px;
  position: absolute;
  left: 2%;
  border: 1px solid #c0c0bf;
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #2e2d29;
  padding-left: 19px;
  outline: none;
}

.shiftbarMax::placeholder {
  color: black;
  font-weight: bold;
}

.shiftDataButtonMax {
  position: absolute;
  width: 122px;
  height: 4.19921875%;
  left: 387px;
  background: #8c1515;
  border-radius: 4px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  padding-top: 0.575em;
  padding-bottom: 0.575em;
}

.shiftDataButtonMax:hover {
  color: #ffffff !important;
  background-color: #701010;
}

.undoButtonMax {
  position: absolute;
  width: 78px;
  height: 24px;
  left: 541px;
  margin-top: 2px;
  margin-bottom: 2px;
  letter-spacing: 0.2px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  text-align: right;
  color: #2e2d29;
  border: 0;
  background-image: url("../img/undoIcon.png");
  background-repeat: no-repeat;
  background-size: 22px 18px;
  background-position: top 2px left 1px;
}

.undoButtonMax:hover {
  color: #701010;
  border: 1px solid #701010;
}

.alertIcon {
  padding: 2em 2em 0em 2em;
  margin-top: 3em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: black;
  height: 80%;
  justify-self: center;
  align-items: center;
}

.visualizationModalExpandButton {
  display: flex;
  justify-content: right;
  padding: 1em;
}

.TabbedMenuForVisulisation {
  padding: 0 2em;
}
