.loginBox {
  font-size: larger;
}

.pointer {
  cursor: pointer !important;
}

.expand-button {
  position: absolute;
  right: 10px;
  top: 10px;
}
