
td {
  text-align: left;
  padding: 8px;
  text-transform: capitalize;
}
  
tr {
  height: 20px;
}
  
.SearchContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;
  height: 50px;
  width: 75%;
  background-color: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #C0C0BF;
}

.SearchinputIcon {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: center;
  order: 0;
  width: 5%;
  background-color: #FFFFFF;
}

.Searchinput {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  border: 0;
  width: 95%;
  min-width: 90%;
  padding: 10px;
  outline: none;
  color: #53565A;
  font-family: 'Roboto';
  font-size: 16px;
  letter-spacing: 0.1px;    
}

.SearchInput::placeholder {
  color: #C0C0BF;
}

.pagination {
  font-size: 16px !important;
  font-family: 'Roboto' !important;
  width: 40% !important;
  align-self: center;
}

/* Taken from node modules */
.rc-pagination-item {
  min-width: 32px !important;
  height: 32px !important;
  font-family: 'Roboto' !important;
  line-height: 30px !important;
  background-color: #F3F3F3 !important;
  border: 1px solid #F3F3F3 !important;
}

.rc-pagination-item:focus,
.rc-pagination-item:hover {
  border: 1px solid #8C1515 !important;
  color: #8C1515 !important;
}

.rc-pagination-item-active {
  font-weight: 700 !important;
  background: rgba(140,21,21, 0.1) !important;
  color: #8C1515 !important;
}


.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  min-width: 32px !important; 
  height: 32px !important;
  color: black !important;
  font-family: 'Roboto' !important;
  line-height: 32px !important;
}

.rc-pagination-prev .rc-pagination-item-link,
.rc-pagination-next .rc-pagination-item-link {
  font-size: 18px !important;
  text-align: center;
  background-color: #f3f3f3 !important;
  border: 1px solid #f3f3f3 !important;
  font-weight: 500 !important;
}

.rc-pagination-prev:focus .rc-pagination-item-link,
.rc-pagination-next:focus .rc-pagination-item-link,
.rc-pagination-prev:hover .rc-pagination-item-link,
.rc-pagination-next:hover .rc-pagination-item-link {
  color: #8C1515 !important;
  border: 1px solid #8C1515 !important;
  font-weight: 700 !important;
}
