.logoSizing {
  padding-top: 5%;
  padding-left: 10%;
  padding-bottom: 10%;
  text-decoration: none;
  display: block;
  transition: 0.3s;
  width: 100%;
  height: auto;
}

.collapsing {
  transition: none !important;
}

.dimmed.dimmable > .ui.animating.dimmer, .dimmed.dimmable > .ui.visible.dimmer, .ui.active.dimmer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dimmer { background-color: rgba(192,192,191,0.4) !important }

.ui.modal,
.ui.active.modal {
  margin: 0 auto!important;
  top: auto !important;
  left: auto !important;
  transform-origin: center !important;
  transition: all ease .5s;
  height: 40% !important;
  width: 40% !important;
}

/* Fixed conflict with bootstrap.min.css*/

.modal-backdrop {
  background-color: rgba(27, 27, 27, 0.2) !important;
  width: 100% !important;
  height: 100% !important;
  border: 0;
  margin: 0;
  top: 0;
  left: 0;
  position: absolute !important;
}

.modal-contents {
  /* This is for the background for content in the modal*/
  height: 666px;
  width: 904px;
  background-color: #ffffff;
  border: 0;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 8px;
}

.modal-contents2 {
  /* This is for the background for content in the modal*/
  height: 367px !important;
  width: 617px !important;
  background-color: #ffffff !important;
  border: 0 !important;
  margin: 0 !important;
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  border-radius: 8px !important;
}

.modalcloseButton {
  float: right;
  background-color: #ffffff !important;
  border: 0 !important;
}

.modalcloseButtonSizing {
  height: 32px;
  width: 32px;
}

.closePositioning {
  position: absolute;
  float: right;
  width: 17px;
  height: 17px;
  right: 40px;
  top: 20px;
}

.buttonContainer {
  float: right;
  padding-top: 40px;
  padding-right: 38px;
}

.modalTitle {
  font-family: "IBM Plex Sans";
  font-weight: 700;
  font-size: 24px;
  color: #2e2d29;
  line-height: 31px;
  letter-spacing: -0.02em;
  padding-bottom: 16px;
  padding-left: 38px;
  padding-top: 70px;
}

.modalDescription {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  color: #2e2d29;
  padding-left: 38px;
}

.horizontalmodalline {
  text-align: center;
  width: 819px;
  border: 1px solid #d7dbe3;
}

.horizontalmodallinespacing {
  padding-top: 32px;
  padding-bottom: 32px;
}

.emailTitleDescriptor {
  color: #2e2d29;
  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  padding-bottom: 8px;
  padding-left: 38px;
}

.titleInputAlignment {
  text-align: center;
  padding-bottom: 32px;
}

.titleInput {
  width: 803px;
  height: 45px;
  border: 1px solid #d7dbe3;
  border-radius: 8px;
  font-size: 16px;
  background-color: #f8f8f8;
  outline: none;
  padding-left: 25px;
  font-family: "IBM Plex Sans";
  font-weight: 500;
}

.descriptionInputDescriptor {
  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  padding-bottom: 16px;
  padding-left: 38px;
}

.descriptionInputAllignment {
  text-align: center;
  padding-bottom: 32px;
}

.descriptionInput {
  width: 778px;
  height: 116px;
  border-radius: 8px;
  outline: none;
  font-size: 16px;
  font-family: "IBM Plex Sans";
  font-weight: 500;
  background-color: #f8f8f8;
  border: 1px solid #d7dbe3;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 16px;
  padding-bottom: 16px;
  resize: none;
}

.addAttachmentsspacing {
  padding-left: 38px;
}

.addAttachments {
  width: 160px;
  height: 30px;
  background-color: #ffffff;
  border: 0;
  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.10000000149011612px;
  text-align: right;
  color: #2e2d29;
  background-repeat: no-repeat;
  background-size: 11px 22px;
  background-position: top 1px left 7px;
}

.addAttachments:active {
  color: #701010;
  border: 1px solid #701010;
}

.submitButton {
  width: 87px;
  height: 44px;
  background-color: #b1040e;
  border: 0;
  border-radius: 4px;
  color: #fafafa;
  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
}

.submitButtonSpacing {
  padding-left: 38px;
  padding-top: 32px;
  padding-bottom: 32px;
}

.submitButton:active {
  background-color: #8d030b;
}

.navLinks {
  display: flex;
  flex-direction: column;
}

.navLinks a {
  color: grey;
  padding: 1em 0;
  font-size: 1.2em;
  display: flex;
  justify-content: space-evenly;
}
.navLinks .active {
  color: #701010;
}
