.verticalCenter {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 20.8333vw;
}

.logoStyling {
  padding-top: 0;
  padding-bottom: 64px;
  padding-left: 0;
  width: 190px;
  height: auto;
}

.formContainer {
  width: 20.8333vw;
}

.emailSpacer {
  padding-bottom: 16px;
}

.passwordSpacer {
  padding-bottom: 32px;
}

/* Fixed bootstrap.min.css issues */
.box2 {
  display: flex;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 16px 0px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #c0c0bf;
  width: 400px;
  margin-bottom: 0;
}

.emailContainerField {
  height: 64px;
  font-size: 24px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  color: #2e2d29;
  border: 0;
  padding-left: 32px;
  width: 400px;
  outline: none;
  width: 100%;
}

.emailContainerField::placeholder {
  color: #c0c0bf;
}

.PasswordContainerField::placeholder {
  color: #c0c0bf;
}

.continueButton {
  width: 400px;
  height: 56px;
  color: #ffffff;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  background-color: #8c1515;
  border-radius: 4px;
  border: 1px;
}

.continueButton:active {
  background-color: #701010;
}

.lineStyling {
  width: 400px;
  margin: 32px 0px;
  border: 1px solid #c0c0bf;
  transform: rotate(0.14deg);
}

/* This is to make the text for the bottom two buttons dissapear for smaller screens*/

.showHide {
  display: none;
}

@media screen and (min-width: 1000px) {
  .showHide {
    display: block;
  }
}

.flexcontainer {
  position: static;
  width: 400px;
  height: 64px;
  left: 0px;
  top: 80px;
  display: flex;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 16px 0px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #c0c0bf;
}

.showButton {
  position: absolute;
  width: 38px;
  height: 20px;
  left: 330px;
  border: 0;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px; /* is supposed to be 16 */
  color: #8c1515;
  margin-top: 0.75em;
  padding: 0.5em;
}

.showButton:hover {
  cursor: pointer;
}

.showButton:focus {
  border: 1px solid;
  border-radius: 5px;
  height: 37px;
}

.PasswordContainerField {
  font-size: 24px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  color: #2e2d29;
  background-color: #ffffff !important;
  padding-left: 32px;
  width: 298px;
  outline: none;
  border: 0;
}

.gmailButton {
  width: 400px;
  height: 56px;
  background-color: #ffffff;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #2e2d29;
  background-image: url("../images/gmailIcon.png");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: top 16px left 32px;
  border: 1px solid #979694;
  border-radius: 4px;
  text-align: left;
  padding-left: 96px;
}

.gmailButton:active {
  background-color: #cccccc;
}

.gmailbuttonSpacer {
  padding-bottom: 16px;
}

.stanfordButton {
  width: 400px;
  height: 56px;
  background-color: #ffffff;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #2e2d29;
  background-image: url("../images/stanfordIcon.png");
  background-repeat: no-repeat;
  background-size: 15.92px 24px;
  background-position: top 16px left 36px;
  border: 1px solid #979694;
  border-radius: 4px;
  text-align: left;
  padding-left: 95px;
}

.stanfordButton:active {
  background-color: #cccccc;
}
