.YearMonthViewSpacer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: normal;
    align-items: center;
    align-content: normal;
    margin-top: 5%;
}

.DateSelector {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
    width: 100%;
}

.deviceName {
    font-size: 42px;
    font-family: 'Roboto';
    font-weight: 700;
    margin-top: 4%;
    margin-left: 2%;
    float: left;
    color: #2E2D29;
}

.bottomVisual {
    margin-top: 6%;
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
    width: 100%;
    height: 100%;
}

.visualizationFlexer {
    height: 61.5vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: normal;
    align-items: stretch;
    align-content: normal;
}

.visualizationMonthView {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
    width: 100%;
    margin-right: 2%;
    margin-left: 2%;
}