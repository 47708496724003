.tileContainer {
  height: 55vh;
  margin-top: 5%;
}

.topRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 50%;
}

.bottomRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 50%;
}

.tileA {
  border: 0.75px solid #c0c0bf;
  border-radius: 3px;
  width: 50%;
  height: 95%;
  margin-bottom: 0;
  margin-left: 25px;
  margin-right: 12.5px;
}

.tileB {
  border: 0.75px solid #c0c0bf;
  border-radius: 3px;
  width: 50%;
  height: 95%;
  margin-bottom: 0;
  margin-left: 12.5px;
  margin-right: 25px;
}

.buttonArea {
  border: 1px solid purple;
  float: right;
  padding-top: 1em;
  padding-right: 2em;
  width: 50px;
  height: 2.5em;
  background-color: red;
}

.CenteredExpandedDayViewBackdrop {
  background-color: rgba(27, 27, 27, 0.4);
  width: 100vw;
  position: fixed;
  top: 50%;
  right: 0%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(0%, -50%);
  height: 100%;
  border: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.CenteredExpandedDayView {
  height: 80%;
  width: 80%;
  background-color: #ffffff;
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  border-radius: 8px;
}

.closeButtonEDVStyling {
  position: relative;
  left: 97%;
  top: 2%;
  z-index: 3;
}

.visualSpacing {
  margin-left: 24px;
  margin-right: 24px;
  min-height: 60vh;
}
