#main {
    height: 100vh;
    width: 100%;
}

body {
    margin: 0;
}

.box {
    display: flex;
    margin-left: 30px;
    margin-top: 30px;
    width: 845px;
    height: 43px;
    border: 1px solid #C0C0BF;
    border-radius: 4px;
    background-color: #FFFFFF;
}

.searchIconSpacer {
    margin-left: 16px;
}

.searchIcon {
    width: auto;
    height: 16px;
    border: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.searchBar {
    margin-top: 14px;
    margin-bottom: 13px;
    margin-right: 16px;
    width: 100%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #C0C0BF;
    margin-left: 12px;
    border: 0;
    outline: none;
}

.studiesTitle {
    text-align: left;
    margin-top: 33px;
    margin-bottom: 11px;
    margin-left: 32px;
    color: #2E2D29;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.3px;
}

.titleDescriptor {
    text-align: left;
    margin-left: 32px;
    margin-bottom: 32px;
    color: #2E2D29;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
}