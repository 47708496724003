.topnav {
  overflow: hidden;
  text-align: right;
  display: flex;
  justify-content: end;
  padding: 1em;
  align-items: center;
  background-color: #FFFFFF;
}

.headerDivider {
  border-left: 1px solid #d5d6d8;
  height: 25px;
  margin: 2px 1em;
  display: inline-block;
}

.modal-content {
  /* This is a tooltip.scss override */
  border: none !important;
}

.modal-body {
  /* This is a tooltip.scss override */
  padding: 0 !important;
}