.fillWindow {
  margin: 0;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

.rightSide {
  background-color: #f3f3f3;
  height: 100%;
  padding: 0 auto;
  margin: 0 auto;
  border-width: 0;
  font-weight: 100;
  margin-left: 210px;
  padding: 1em 2em;
  overflow-x: hidden;
  position: relative;
}

.sidebar {
  background-color: #ffffff;
  height: 100%;
  margin: 0 auto;
  padding: 0 auto;
  border-width: 0;
  font-weight: medium;
  position: absolute;
}

.tempstyle {
  background-color: #8c1515;
  color: #ffffff;
  text-decoration: none !important;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 5px;
  float: right;
}

.tempstyle:hover {
  color: white;
}
